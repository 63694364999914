import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import useAuthentication from "../../../../hooks/useAuthentication";

import App from "./App";

import "../scss/App.scoped.scss";

function AppGuard(props) {
  const { auth0, inboxState, userState } = props;

  const location = useLocation();

  const { AuthenticationLoader, logOut } = useAuthentication(auth0, userState);

  if (userState.isLoading) {
    return <AuthenticationLoader />;
  }

  if (!userState.isAuthenticated && location.pathname !== "/login") {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { targetUrl: `${location.pathname}${location.search}` },
        }}
      />
    );
  }

  return !userState.user ? (
    <AuthenticationLoader />
  ) : (
    <App inboxState={inboxState} logOut={logOut} userState={userState} />
  );
}

export default observer(AppGuard);
