const mfActivatedWhen = {
  "@InReachVentures/auth": {
    rules: [
      {
        rule: "equals",
        value: "/login",
      },
      {
        rule: "equals",
        value: "/callback",
      },
    ],
  },
  "@InReachVentures/nav": {
    rules: [
      {
        rule: "not_equals",
        value: "/login",
      },
      {
        rule: "not_equals",
        value: "/callback",
      },
    ],
  },
  "@InReachVentures/organizations": {
    rules: [
      {
        rule: "equals",
        value: "/organizations",
      },
      {
        rule: "equals",
        value: "/discover",
      },
      {
        rule: "equals",
        value: "/spam",
      },
    ],
  },
  "@InReachVentures/dig": {
    rules: [
      {
        rule: "equals",
        value: "/organizations/create",
      },
      {
        rule: "equals",
        value: "/investors",
      },
      {
        rule: "equals",
        value: "/people",
      },
      // {
      //   rule: "equals",
      //   value: "/inbox",
      // },
      {
        rule: "equals",
        value: "/overview",
      },
      {
        rule: "equals",
        value: "/tasks",
      },
      {
        rule: "equals",
        value: "/incomplete",
      },
      {
        rule: "equals",
        value: "/incompletes",
      },
      {
        rule: "regex",
        value: /[/]tasks[/][*+]?/,
      },
      {
        rule: "equals",
        value: "/overview/reconnect",
      },
      {
        rule: "regex",
        value: /[/]organizations[/][*+]?/,
      },
      {
        rule: "regex",
        value: /[/]people[/][*+]?/,
      },
      {
        rule: "regex",
        value: /[/]investors[/][*+]?/,
      },
    ],
  },
  "@InReachVentures/inbox": {
    rules: [
      {
        rule: "equals",
        value: "/inbox",
      },
    ],
  },
  "@InReachVentures/funding-link-tool": {
    rules: [
      {
        rule: "equals",
        value: "/funding-link-tool",
      },
    ],
  },
};

const navActivatedWhen = {
  create: {
    rules: [
      {
        rule: "equals",
        value: "/organizations/create",
      },
    ],
  },
  organizations: {
    rules: [
      {
        rule: "equals",
        value: "/organizations",
      },
      {
        rule: "regex",
        value: /[/]organizations[/][[\d]+]?/,
      },
    ],
  },
  discover: {
    rules: [
      {
        rule: "equals",
        value: "/discover",
      },
    ],
  },
  overview: {
    rules: [
      {
        rule: "equals",
        value: "/overview",
      },
      {
        rule: "equals",
        value: "/overview/reconnect",
      },
    ],
  },
  inbox: {
    rules: [
      {
        rule: "equals",
        value: "/inbox",
      },
    ],
  },
  people: {
    rules: [
      {
        rule: "equals",
        value: "/people",
      },
      {
        rule: "regex",
        value: /[/]people[/][[\d]+]?/,
      },
    ],
  },
  investors: {
    rules: [
      {
        rule: "equals",
        value: "/investors",
      },
      {
        rule: "regex",
        value: /[/]investors[/][[\d]+]?/,
      },
    ],
  },
  spam: {
    rules: [
      {
        rule: "equals",
        value: "/spam",
      },
    ],
  },
  "funding-link-tool": {
    rules: [
      {
        rule: "equals",
        value: "/funding-link-tool",
      },
    ],
  },
};

const equalTest = (label) => {
  return (location) => label === location;
};

var notEqualTest = function (label) {
  return (location) => label !== location;
};

var regexTest = function (regex) {
  return (location) => regex.test(location);
};

export const navActiveWhen = (id, pathname) => {
  const activeWhen = navActivatedWhen[id];

  const testsPositive = activeWhen.rules.reduce((acc, rule) => {
    switch (rule.rule) {
      case "equals":
        acc.push(equalTest(rule.value));
        break;
      case "regex":
        acc.push(regexTest(rule.value));
        break;
    }
    return acc;
  }, []);

  const testsNegative = activeWhen.rules.reduce((acc, rule) => {
    switch (rule.rule) {
      case "not_equals":
        acc.push(notEqualTest(rule.value));
        break;
    }
    return acc;
  }, []);

  return testsPositive.length > 0
    ? testsPositive.find((test) => test(location.pathname))
    : testsNegative.every((test) => test(location.pathname));
};

export default (id) => {
  const activeWhen = mfActivatedWhen[id];

  const testsPositive = activeWhen.rules.reduce((acc, rule) => {
    switch (rule.rule) {
      case "equals":
        acc.push(equalTest(rule.value));
        break;
      case "regex":
        acc.push(regexTest(rule.value));
        break;
    }
    return acc;
  }, []);

  const testsNegative = activeWhen.rules.reduce((acc, rule) => {
    switch (rule.rule) {
      case "not_equals":
        acc.push(notEqualTest(rule.value));
        break;
    }
    return acc;
  }, []);

  return (location) => {
    return testsPositive.length > 0
      ? testsPositive.find((test) => test(location.pathname))
      : testsNegative.every((test) => test(location.pathname));
  };
};
