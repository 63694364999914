import axios from "axios";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";
import { useCollapse, STATE } from "../../../../hooks/useTransitionControl";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import "../scss/App.scoped.scss";
import logo from "../static/inReach_logo.svg";

import config from "../../../../config";
import { navActiveWhen } from "../../../../const/routing";

const classes = {
  entering: "navbar-collpase collapsing",
  entered: "collpase navbar-collpase show",
  exiting: "navbar-collpase collapsing",
  exited: "navbar-collpase collapse",
};

const threeDotsActive = [
  "/discover",
  "/people",
  "/investors",
  "/spam",
  "/funding-link-tool",
];

const threeDotsLabels = {
  "/discover": "Discover",
  "/people": "People",
  "/investors": "Investors",
  "/spam": "Spam",
  "/funding-link-tool": "Funding Link Tool",
};

function App(props) {
  const { inboxState, logOut, userState } = props;

  const location = useLocation();

  const [state, toggle, enter] = useCollapse(
    350,
    STATE.EXITED,
    "#navbarNav",
    classes,
    userState.isLoading
  );
  const { width } = useWindowDimensions();
  const [navRef, navVisible] = useIntersectionObserver({
    root: null,
    rootMargin: "90px",
    threshold: 1.0,
  });

  const apiUrl = new URL(config.api.host + "/organizations");

  const collectInboxItemsNumber = async () => {
    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
      user_inbox: userState.user.email,
      inbox: true,
      limit: 0,
    });
    const url = new URL(apiUrl);
    url.search = searchParams.toString();
    const organizations = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    });
    const inboxItemsNumber = organizations.data.total;
    inboxState.setCounterInbox(inboxItemsNumber);
  };

  useEffect(() => {
    if (width > 768) {
      enter();
    }
  }, [width]);

  useEffect(() => {
    const navVisibleEvent = new CustomEvent("nav_visible", {
      detail: navVisible,
    });
    window.dispatchEvent(navVisibleEvent);
  }, [navVisible]);

  useEffect(() => {
    if (userState.token && userState.user && userState.client) {
      collectInboxItemsNumber();
    }
  }, [userState.token, userState.user, userState.client]);

  return (
    <nav class="navbar navbar-light navbar-expand-md" ref={navRef}>
      <div
        className={clsx(
          "container-fluid",
          width > 992 && "justify-content-between"
        )}
      >
        <span class="navbar-brand mb-0 h1" href="#">
          <img
            src={logo}
            alt=""
            width="24"
            height="24"
            class="d-inline-block align-top me-1"
          />
          DIG
        </span>
        <button
          class="navbar-toggler"
          type="button"
          onClick={(event) => {
            toggle();
          }}
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className={classes[state]} id="navbarNav">
          <div className={clsx(width > 992 && "d-flex flex-fill")}>
            <ul class="navbar-nav mb-2 me-auto mw-0 mb-lg-0">
              <li class="nav-item">
                <Link
                  className={clsx(
                    "nav-link",
                    navActiveWhen("create", location.pathname) && "active"
                  )}
                  to={{
                    pathname: "/organizations/create",
                    state: { from: location.pathname },
                  }}
                  data-testid="create"
                >
                  <span class="bi bi-plus-square"></span>
                  Create organization
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  className={clsx(
                    "nav-link",
                    navActiveWhen("organizations", location.pathname) &&
                      "active"
                  )}
                  to={{
                    pathname: "/organizations",
                    state: { from: location.pathname },
                  }}
                  data-testid="organizations"
                >
                  <span class="bi bi-building"></span>
                  Organizations
                </Link>
              </li>
              {userState.accessType === "full" && (
                <li class="nav-item" data-testid="overview_li">
                  <Link
                    className={clsx(
                      "nav-link",
                      navActiveWhen("overview", location.pathname) && "active"
                    )}
                    to={{
                      pathname: "/overview",
                      state: { from: location.pathname },
                      search: new URLSearchParams({
                        assigned_to: userState.user.email,
                      }).toString(),
                    }}
                    data-testid="overview"
                  >
                    <span class="bi bi-list"></span>
                    Overview
                  </Link>
                </li>
              )}
              {userState.accessType === "full" && (
                <li class="nav-item">
                  <Link
                    className={clsx(
                      "nav-link",
                      navActiveWhen("inbox", location.pathname) &&
                        "active" &&
                        "active"
                    )}
                    to={{
                      pathname: "/inbox",
                      state: { from: location.pathname },
                    }}
                    data-testid="inbox"
                  >
                    <span class="bi bi-inbox"></span>
                    Inbox
                    {inboxState.counterInbox > 0 && (
                      <span
                        class="badge bg-dark mx-1 d-inline-block"
                        data-testid="inboxCounter"
                      >
                        {inboxState.counterInbox}
                      </span>
                    )}
                  </Link>
                </li>
              )}
              {userState.accessType === "full" && (
                <li class="nav-item dropdown" data-testid="full_menu">
                  <a
                    className={clsx(
                      "nav-link",
                      threeDotsActive.includes(location.pathname) && "active"
                    )}
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots"></i>
                    {threeDotsActive.includes(location.pathname) &&
                      threeDotsLabels[location.pathname]}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    {userState.accessType === "full" && (
                      <li class="dropdown-item">
                        <Link
                          className={clsx(
                            "nav-link",
                            navActiveWhen("discover", location.pathname) &&
                              "active"
                          )}
                          to={{
                            pathname: "/discover",
                            state: { from: location.pathname },
                          }}
                          data-testid="discover"
                        >
                          <span class="bi bi-card-checklist"></span>
                          Discover
                        </Link>
                      </li>
                    )}
                    {userState.accessType === "full" && (
                      <li class="dropdown-item">
                        <Link
                          className={clsx(
                            "nav-link",
                            navActiveWhen("people", location.pathname) &&
                              "active"
                          )}
                          to={{
                            pathname: "/people",
                            state: { from: location.pathname },
                          }}
                          data-testid="people"
                        >
                          <span class="bi bi-people"></span>
                          People
                        </Link>
                      </li>
                    )}
                    <li class="dropdown-item">
                      <Link
                        className={clsx(
                          "nav-link",
                          navActiveWhen("investors", location.pathname) &&
                            "active"
                        )}
                        to={"/investors"}
                        data-testid="investors"
                      >
                        <span class="bi bi-wallet2"></span>
                        Investors
                      </Link>
                    </li>
                    {userState.accessType === "full" && (
                      <li class="dropdown-item">
                        <Link
                          className={clsx(
                            "nav-link",
                            navActiveWhen("spam", location.pathname) && "active"
                          )}
                          to={{
                            pathname: "/spam",
                            state: { from: location.pathname },
                          }}
                          data-testid="spam"
                        >
                          <span class="bi bi-shield-x"></span>
                          Spam
                        </Link>
                      </li>
                    )}
                    {userState.accessType === "full" && (
                      <li class="dropdown-item">
                        <Link
                          className={clsx(
                            "nav-link",
                            navActiveWhen(
                              "funding-link-tool",
                              location.pathname
                            ) && "active"
                          )}
                          to={{
                            pathname: "/funding-link-tool",
                            state: { from: location.pathname },
                          }}
                          data-testid="funding-link-tool"
                        >
                          <span class="bi bi-link-45deg"></span>
                          Funding Link Tool
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
            <div class="d-flex">
              <p class="me-3 m-0 d-flex justify-content-center align-items-center">
                Hello&nbsp;
                <b data-testid="name">{userState?.user?.given_name || ""}</b>
              </p>
              <button
                id="nav-logout-button"
                class="btn btn-outline-primary"
                onClick={() => {
                  logOut();
                }}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default observer(App);
