import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AppGuard from "./components/AppGuard";

import "./scss/root.component.scoped.scss";

export default function Root(props) {
  const { auth0, inboxState, userState } = props;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/organizations" />
        </Route>
        <Route
          path="*"
          render={() => (
            <AppGuard
              auth0={auth0}
              inboxState={inboxState}
              userState={userState}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}
